<template>
	<div class="results-table" :class="computedClass">
		<div v-if="!isMobileTableView" class="results-table__letter results-table__letter--relative">
			{{ letter }}
		</div>

		<QExpandableDataTable
			:key="resizeKey"
			:headers="visibleHeaders"
			:items="filteredItems"
			:page.sync="computedOptions.page"
			:items-per-page.sync="computedOptions.itemsPerPage"
			:sort-by.sync="computedOptions.sortBy"
			:sort-desc.sync="computedOptions.sortDesc"
			:expanded.sync="expanded"
			:search="search"
			:item-key="itemKey"
			class="results-table__table"
			color="#54C1B2"
			:footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }"
			:show-expand="showExpand"
		>
			<template v-if="isMobileTableView" v-slot:header.q-expand-icon>
				<div class="results-table__letter">
					{{ letter }}
				</div>
			</template>

			<template v-for="h in visibleHeaders" v-slot:[`item.${h.value}`]="{ item, value, index }">
				<div :key="`${h.value}-${index}`" :class="h.cellClass">
					<slot :name="`item.${h.value}`" v-bind="{ item, value, header: h }">
						{{ formatItem(value, h.value) }}
					</slot>
				</div>
			</template>

			<template v-if="showExpand" v-slot:expand="{ item }">
				<div class="results-table__expand-wrapper">
					<v-simple-table v-if="isMobileTableView">
						<tbody>
							<tr v-for="header in expandedHeaders" :key="header.value">
								<td class="text-start cell-title">
									{{ header.text }}
								</td>
								<td class="text-end">
									<slot :name="`item.${header.value}`" v-bind="{ item, value: item[header.value], header }">
										{{ formatItem(item[header.value], header.value) }}
									</slot>
								</td>
							</tr>
						</tbody>
					</v-simple-table>
					<div v-else class="results-table__line-flex">
						<div v-for="h in expandedHeaders" :key="h.value">
							<span class="cell-title">{{ h.text }}</span>:
							<span class="results-table__line-flex-content">
								<slot :name="`item.${h.value}`" v-bind="{ item, value: item[h.value], header: h }">
									{{ formatItem(item[h.value], h.value) }}
								</slot>
							</span>
						</div>
					</div>
				</div>
			</template>
		</QExpandableDataTable>
	</div>
</template>

<script>
import moment from 'moment'

import QExpandableDataTable from '@/components/datatables/QExpandableDataTable.vue'

import { config } from '../config/siteIndexConfigs'

const defaultOptions = {
	sortBy: [],
	sortDesc: [],
	page: 1,
	itemsPerPage: 25,
}

export default {
    components: {
        QExpandableDataTable,
    },
    props: {
		items: {
			type: Array,
			default: () => [],
		},
		letter: {
            type: String,
        },
		search: {
            type: String,
            default: '',
        },
		categories: {
			type: Array,
			default: () => [],
        },
		error: {
			type: Boolean,
			default: false,
		},
		options: {
			type: Object,
			default: () => ({ ...defaultOptions }),
		},
    },
    data () {
        return {
			config,
			itemKey: 'id',
            expanded: [],
			resizeKey: 0,
        }
    },
    computed: {
		computedOptions: {
			get () { return this.options },
			set (v) { this.$emit('update-options', v) },
		},
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		isTabletView () {
			return this.$vuetify.breakpoint.smAndDown && !this.isMobileView
		},
		isMobileTableView () {
			return this.isMobileView || this.isTabletView
		},
		computedClass () {
			return [
				this.isMobileView && 'results-table--mobile',
				this.isTabletView && 'results-table--tablet',
			]
		},
		headers () {
			return this.config.map(item => ({
                text: item.label,
                value: item.key,
				filterable: item.filterable ?? true,
				...(item.sort && { sort: item.sort }),
			}))
		},
		visibleHeaders () {
			return this.isMobileTableView
				? this.headers.filter(({ value }) => this.config.find(({ key }) => key === value).mobileVisible)
				: this.headers.filter(({ value }) => this.config.find(({ key }) => key === value).visible !== false)
		},
		expandedHeaders () {
			return this.headers.filter(({ value }) => !this.visibleHeaders.some(h => h.value === value))
		},
		showExpand () {
			return this.expandedHeaders.length !== 0
		},
		filteredItems () {
			if (this.error) { return [] }
			if (!this.items.length) { return [] }
			if (!this.categories.length) { return this.items }
			return this.items.filter(({ category_name }) => this.categories.includes(category_name))
		},
    },
    methods: {
		formatItem (value, headerKey) {
			const config = this.config.find(({ key }) => key === headerKey)
			if (config.type === 'date') {
				return config.format || config.toFormat
					? moment(value, config.format).format(config.toFormat)
					: value
			}
			if (value === undefined || value === null) {
				return '-'
			}
			return value
		},
    },
    watch: {
		isMobileTableView (curr, prev) {
			if (curr !== prev) {
				this.resizeKey += 1
			}
		},
    },
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

$block: results-table;

.#{$block} {
	position: relative;
	background-color: $color-white;
	width: 100%;
	display: flex;
	column-gap: 2rem;

	--size: 3rem;
	--font-size-letter: 1.5rem;
	
	&--tablet,
	&--mobile {
		--size: 2rem;
		--font-size-letter: 1rem;
	}

	&--tablet,
	&--mobile {
		.#{$block}__table ::v-deep {
			thead tr th {
				height: 36px;
				text-wrap: wrap;
			}

			tr:not(.v-data-table__progress) th,
			tr td {
				padding: 0 0.5rem;
			}

			.v-data-footer {
				padding: 0;

				.v-data-footer__select {
					margin-right: 2rem;

					.v-select {
						margin-left: 1rem;
					}
				}

				.v-data-footer__pagination {
					margin: 0 1rem 0 0;
				}

				.v-btn {
					margin: 0;
				}
			}
		}
	}

	&__letter {
		flex: 0 0 var(--size);
		width: var(--size);
		height: var(--size);
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		border: 1px solid $color-accent-dark;
		background-color: $color-accent-dark;
		color: $color-white;
		font-size: var(--font-size-letter);
		font-weight: 600;
		line-height: 1;

		&--relative {
			position: relative;
			top: var(--size);
		}
	}

	&__table {
		flex-grow: 1;
		background-color: $color-white;

		::v-deep {
			thead tr:not(.v-data-table__progress) th {
				font-size: 0.8em;
				vertical-align: bottom;
				line-height: 1;
				border-bottom-width: 3px !important;

				&.q-expand-icon {
					padding: 0;
					width: 2rem;
				}
			}

			tbody tr td {
				line-height: 1.4;
			}

			tbody tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
				background: none;
			}

			.v-data-table__expanded.v-data-table__expanded__content {
				box-shadow: none;

				.q-expand-table__expand-td {
					background-color: $color-white !important;
					padding: 0.25rem !important;
	
					table tbody tr:last-of-type td {
						border-bottom: none;
					}

					table tr {
						background-color: transparent !important;
					}
				}
			}
		}
	}

	&__header-letter {
		display: inline-flex;
		column-gap: 1rem;
	}

	&__expand-wrapper {
		padding: 0.5rem;
		border-radius: 0.25rem;
		color: #00000099;
		box-shadow: 0px 1px 2px 2px #eeeeee;
	}

	&__line-flex {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		row-gap: 0.5rem;
		column-gap: 3rem;

		&-content {
			margin-left: 0.5rem;
			white-space: nowrap;
		}
	}

	::v-deep .cell-title {
		font-weight: 600;
	}
}
</style>
