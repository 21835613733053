import api from '../../../store/API/QuilityAPI'

type ErrorResponse = {
	error: boolean
	message?: string
}

type LettersCountResponse = {
	data: Record<string, string | number | null>[]
}

type PagesResponse = {
	data: Record<string, string | number | null>[]
	meta: unknown[]
}

export const loadLettersCount = async () => {
	return api.getRequest('/api/private/cms/letters-count')
	    .then((response) => {
			return response as LettersCountResponse
		})
		.catch((err) => {
			const message = err.status === 404 ? 'Page not found.' : err.message
	        return { error: true, message } as ErrorResponse
	    })
}

export const loadSiteIndexPages = async (letter: string) => {
	return api.getRequest(`/api/private/cms/index/${letter}`)
	    .then((response) => {
			return response as PagesResponse
		})
		.catch((err) => {
			const message = err.status === 404 ? 'Page not found.' : err.message
	        return { error: true, message } as ErrorResponse
	    })
}

export const loadSiteIndexPagesByCategory = async (params: Record<string, unknown>) => {
	return api.getRequest('/api/private/pages', params)
	    .then((response) => {
			return response as PagesResponse
		})
		.catch((err) => {
			const message = err.status === 404 ? 'Page not found.' : err.message
	        return { error: true, message } as ErrorResponse
	    })
}
