<template>
	<v-container fluid class="site-index site-width" :class="computedClass">
		<div class="d-flex align-center justify-center">
			<h1 class="site-index__title">Content Index</h1>
			<v-btn
				icon color="#54C1B2" class="ml-2"
				:disabled="dataLoading || lettersCountLoading"
				@click="reloadData"
			>
				<v-icon>fas fa-sync-alt</v-icon>
			</v-btn>
		</div>

		<AlphabetTabs
			v-model="letter"
			:letters-count="lettersCount"
			:optional="categoryMode"
			class="flex-grow-0 mt-6"
		/>

		<IndexSearchResults
			:letter="letter"
			:items="items"
			:category-mode="categoryMode"
			:loading="dataLoading"
			:error="dataError"
			class="mt-8"
			@load-category="loadCategory"
		/>
	</v-container>
</template>

<script>
import { debounce } from 'vue-debounce'

import AlphabetTabs from '../components/AlphabetTabs.vue'
import IndexSearchResults from '../components/IndexSearchResults.vue'
import { loadLettersCount, loadSiteIndexPages, loadSiteIndexPagesByCategory } from '../api/index'

export default {
    components: {
		AlphabetTabs,
		IndexSearchResults,
	},
    data () {
        return {
			letter: 'A',
			categoryMode: false,

			items: [],
			cachedItems: {},
			dataLoading: false,
			dataError: false,

			lettersCount: [],
			lettersCountLoading: false,
			lettersCountError: false,
        }
    },
	created () {
		this.loadLettersCount()
		this.loadData()
	},
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		isTabletView () {
			return this.$vuetify.breakpoint.mdAndDown && !this.isMobileView
		},
		computedClass () {
			return [
				this.isMobileView && 'site-index--mobile',
				this.isTabletView && 'site-index--tablet',
			]
		},
		agentCode () {
			return this.user.Agent.AgentCode
		},
    },
	watch: {
		letter (val) {
			if (!val) { return }
			this.categoryMode = false
			this.loadData()
		},
	},
	methods: {
		reloadData () {
			this.cachedItems = {}
			this.items = []
			this.categoryMode = false
			this.letter = 'A'
			this.loadLettersCount()
			this.loadData()
		},
		loadCategory (categoryId) {
			this.categoryMode = true
			this.letter = undefined
			this.loadPagesByCategory(categoryId)
		},
		loadLettersCount: debounce(async function () {
			this.setLettersCountLoadingStatus(true, false)
			const res = await loadLettersCount()
			if (res.error) {
				this.lettersCount = []
				this.setLettersCountLoadingStatus(false, true)
				this.showError(`Oops! There was a problem loading Site Index letters count.`, res.message)
			} else {
				this.lettersCount = res.data
				this.setLettersCountLoadingStatus(false, false)
			}
		}, 300),
		loadData: debounce(async function () {
			if (!this.letter) { return }
			const cacheKey = `${this.agentCode}_${this.letter}`
			// check cache
			if (this.cachedItems[cacheKey]) {
				this.items = [...this.cachedItems[cacheKey]]
				this.setDataLoadingStatus(false, false)
				return Promise.resolve()
			}
			// call API
			this.setDataLoadingStatus(true, false)
			const res = await loadSiteIndexPages(this.letter)
			if (res.error) {
				this.items = []
				this.setDataLoadingStatus(false, true)
				this.showError(`Oops! There was a problem loading Site Index data.`, res.message)
			} else {
				this.cachedItems[cacheKey] = res.data
				this.items = [...this.cachedItems[cacheKey]]
				this.setDataLoadingStatus(false, false)
			}
		}, 300),
		loadPagesByCategory: debounce(async function (categoryId) {
			const cacheKey = `${this.agentCode}_category_${categoryId}`
			// check cache
			if (this.cachedItems[cacheKey]) {
				this.items = [...this.cachedItems[cacheKey]]
				this.setDataLoadingStatus(false, false)
				return Promise.resolve()
			}
			// call API
			this.setDataLoadingStatus(true, false)
			this.items = []
			const params = {
				itemsPerPage: 'all',
				sortBy: 'title',
				sortDesc: false,
				category_id: categoryId,
				status: 'publish',
				division: '-All-',
			}
			const res = await loadSiteIndexPagesByCategory(params)
			if (res.error) {
				this.items = []
				this.setDataLoadingStatus(false, true)
				this.showError(`Oops! There was a problem loading Site Index data.`, res.message)
			} else {
				this.cachedItems[cacheKey] = res.data
				this.items = [...this.cachedItems[cacheKey]]
				this.setDataLoadingStatus(false, false)
			}
		}, 300),
		setDataLoadingStatus (loading, error) {
			this.dataLoading = loading
			this.dataError = error
		},
		setLettersCountLoadingStatus (loading, error) {
			this.lettersCountLoading = loading
			this.lettersCountError = error
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

$block: site-index;

.#{$block} {
	--block-padding: 1rem 2rem;

	&--tablet {
		--block-padding: 1rem 1.5rem;
	}

	&--mobile {
		--block-padding: 0.5rem 1rem;
	}

	height: 100%;
	padding: var(--block-padding);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	color: $color-black;

	&__title {
		margin-bottom: 0;
		font-size: calc(28px + (36 - 30) * ((100vw - 320px) / (1600 - 320)));
		font-weight: bold !important;
		letter-spacing: normal;
	}
}
</style>
